.com-classify-header {
  display: flex;
  margin-bottom: 0.2rem;
}

.com-classify-header .icon {
  width: 0.42rem;
  height: 0.4rem;
  margin-right: 0.1rem;
  background: url("./icon-heart.png") no-repeat 50%/cover;
}

.com-classify-header .title {
  color: #fff;
  font-weight: bold;
  font-size: 0.28rem;
}