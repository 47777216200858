.page-result {
  width: 100%;
  overflow: hidden;
  background: url("../../imgs/bg.png") 50% no-repeat #000;
  background-size: cover;
}

.page-result .x-app-body {
  padding: 0;
}

.page-result .slogan {
  width: 100%;
  height: 3.25rem;
  background: url("../../imgs/slogan.png") no-repeat 50%/cover;
}

.page-result .photo-ad {
  margin-top: 0.2rem;
}

.page-result .photo-ad .photo-pass-wrapper {
  position: relative;
  width: 5.8rem;
  height: 7.34rem;
  margin: 0 auto;
  overflow: hidden;
  background: url("../../imgs/gallery-bg.png") no-repeat 50%/cover;
}

.page-result .photo-ad .photo-pass-wrapper .item {
  position: absolute;
  top: 0.42rem;
  left: 0.45rem;
  width: 4.9rem;
  height: 6.5rem;
  opacity: 0;
  transition: opacity 1s linear;
}

.page-result .photo-ad .photo-pass-wrapper .item.local {
  opacity: 1;
}

.page-result .photo-ad .photo-pass-wrapper .item.local.animation {
  opacity: 0;
}

.page-result .photo-ad .photo-pass-wrapper .item.passthrough {
  opacity: 0;
}

.page-result .photo-ad .photo-pass-wrapper .item.passthrough.animation {
  opacity: 1;
}

.page-result .photo-ad .passthrough-handle {
  margin-top: 0.8rem;
  margin-bottom: 0.4rem;
}

.page-result .photo-ad .passthrough-handle .btns {
  position: relative;
  padding: 0 0.4rem;
}

.page-result .photo-ad .passthrough-handle .btns .btn {
  width: 100%;
  height: 1rem;
  margin: 0.2rem 0;
}

.page-result .photo-ad .passthrough-handle .btns .btn.reuoload {
  background: url("../../imgs/btn-upload.png") no-repeat 50%/100%;
}

.page-result .photo-ad .passthrough-handle .btns .btn.again {
  width: 3.14rem;
  height: 0.98rem;
  margin: 0 auto;
  background: url("../../imgs/btn-again.png") no-repeat 50%/100% 100%;
}

.page-result .photo-ad .passthrough-handle .btns .file {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  opacity: 0;
}

.page-result .photo-ad .passthrough-handle .tip {
  margin: 0.2rem 0;
  color: #fff;
  font-weight: bold;
  font-size: 0.26rem;
  text-align: center;
}

.page-result .ads {
  position: relative;
  display: block;
  padding: 0 0.4rem 0.4rem 0.4rem;
  overflow: hidden;
  border-radius: 0.05rem;
}

.page-result .ads .ad {
  position: relative;
  display: block;
  margin-bottom: 0.3rem;
}

.page-result .ads img {
  display: block;
  width: 100%;
  border-radius: 0.05rem;
}

.page-result .ads h2 {
  position: absolute;
  bottom: 0.3rem;
  width: 100%;
  color: #fff;
  font-size: 0.36rem;
  text-align: center;
}