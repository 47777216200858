.com-ad-scan {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("./imgs/scan-bg.png") no-repeat 50%/4.1rem 5.34rem;
}

.com-ad-scan .line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.25rem;
  background: url("./imgs/scan-line.png") no-repeat 50%/4.94rem 0.25rem;
  animation: scanMoveAnimation 3s linear infinite;
}

@keyframes scanMoveAnimation {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(6.5rem);
  }
}