.page-index {
  width: 100%;
}

.page-index .inner {
  position: relative;
  min-height: 100vh;
  padding-top: 0.2rem;
  background: url("../../imgs/bg.png") 50% no-repeat;
  background-size: cover;
}

.page-index .x-app-body {
  padding: 0;
}

.page-index .slogan {
  width: 100%;
  height: 3.25rem;
  margin: 0 auto;
  background: url("../../imgs/slogan.png") no-repeat 50%/100%;
}

.page-index .gallery {
  width: 5.8rem;
  height: 7.34rem;
  margin: 0 auto;
  margin-top: -0.4rem;
  padding: 0.42rem 0.45rem;
  overflow: hidden;
  background: url("../../imgs/gallery-bg.png") no-repeat 50%/cover;
}

.page-index .gallery .photo {
  width: 4.9rem;
  height: 6.5rem;
  background: url("../../imgs/photo-bg.jpg") no-repeat 50%/cover;
}

.page-index .btns {
  position: relative;
  width: 100%;
  margin-top: 0.1rem;
  padding: 0.4rem;
}

.page-index .btns .tip {
  margin-bottom: 0.2rem;
}

.page-index .btns .tip p {
  color: #fff;
  font-weight: bold;
  font-size: 0.36rem;
  text-align: center;
}

.page-index .btns .btn {
  width: 100%;
  height: 1rem;
  background: url("../../imgs/btn-upload.png") no-repeat 50%/100%;
}

.page-index .btns .file {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.page-index .photo-tip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.1rem;
  padding: 0 1rem;
}

.page-index .photo-tip .line {
  flex: 1;
  border-bottom: dashed 0.01rem #fff;
}

.page-index .photo-tip .item span {
  display: block;
  width: 0.4rem;
  height: 0.4rem;
  margin: 0.1rem auto;
  color: #fff;
  font-size: 0.24rem;
  line-height: 0.36rem;
  text-align: center;
  border: solid 0.02rem #fff;
  border-radius: 0.2rem;
}

.page-index .photo-tip .item h6 {
  color: #fff;
  font-size: 0.24rem;
  text-align: center;
}